.home-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #cdcdcd;
    margin-top: 80px;
    padding-bottom: 100px;
}

.header-for-create {
    max-width: 620px;
    font-size: 2.2rem;
    line-height: 1.25;
    font-weight: 600;
    text-align: center;
    opacity: 0.85;
}

.speed {
    color: #ce20a5;
}

.desc {
    margin-top: 30px !important;
    width: 60%;
    opacity: 0.7;
    font-size: 1.25rem;
    text-align: center;
    line-height: 1.5;
}

.get-git-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px !important;
}

.get-started-button {
    padding: 7px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: #D427AC;
    color: white;
    font-weight: 400;
    border-radius: 4px;
    margin-right: 20px;
    cursor: pointer;
    transition: background-color 0.5s;
    font-weight: 500;
}

.github-button {
    padding: 7px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: #e3e8ee;
    font-weight: 400;
    border-radius: 4px;
    color: #000000 !important;
    cursor: pointer;
    transition: background-color 0.5s;
    font-weight: 500;
}

.fa-github {
    font-size: 24px !important;
    margin-right: 5px;
}

.github-button:hover {
    background-color: #d8dde4;
    transition: background-color 0.5s;
}

.get-started-button:hover {
    background-color: #c924a3;
    transition: background-color 0.5s;
}

.d-n-c-img-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    top: 100vh;
    margin-top: -45px;
    width: 100%;
}

.d-n-c-by {
    font-size: 15px;
    opacity: 0.5;
    font-weight: 500;
}

.varun-img {
    border-radius: 70px;
    margin-left: 6px;
    margin-right: 6px;
}

.d-n-c-varun {
    font-size: 16px;
    opacity: 0.6;
    font-weight: 500;
}

.documentation {
    margin: 0 auto;
    margin-top: 80px;
    max-width: 750px;
    min-height: 100vh;
}

.getting-started {
    font-size: 2.5rem;
    font-weight: 600;
}

.highlighter, code {
    font-family: "Poppins", sans-serif !important;
    padding: 20px !important;
    font-size: 17px !important;
    border-radius: 10px;
    margin-top: 20px;
}

h1, h2, h3, h4, h5, h6 {
    opacity: 0.85;
}

.banner-img-all {
    position: relative;
    left: 0;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
    background-position: 50%;
    border-radius: 10px;
}

.sectionHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-top: 60px;
}

.scrollButton {
    padding: 7px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: rgba(76, 106, 220, 0.85);
    color: white;
    font-weight: 400;
    border-radius: 70px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.5s;
    font-weight: 500;
}

.noteFA {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 50px;
    min-height: 40px;
    background-color: #e3e8ee;
    opacity: 0.8;
    border-left: 3px solid #a4a4a4;
    font-size: 16px;
}

.hovered-color {
    transition: 0.4s;
  }
  
  .hovered-color:hover {
    transform: scale(1.4);
    transition: 0.4s;
    z-index: 9999;
    border-radius: 5px;
  }

  .banner-img-all, #npmInstall {
      cursor: pointer;
  }

  .autoWidth {
      width: auto !important;
  }

@media only screen and (max-width: 750px) {

    .home-container {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 30px;
        border-bottom: 1px solid #cdcdcd;
        margin-top: 60px;
        padding-bottom: 100px;
    }

    .header-for-create {
        max-width: 100%;
        font-size: 1.8rem;
        line-height: 1.25;
        font-weight: 600;
        text-align: center;
        opacity: 0.85;
    }

    .desc {
        margin-top: 20px !important;
        width: 100%;
        opacity: 0.7;
        font-size: 1rem;
        text-align: center;
        line-height: 1.5;
    }

    .d-n-c-by {
        font-size: 12px;
        opacity: 0.5;
        font-weight: 500;
    }

    .d-n-c-varun {
        font-size: 13px;
        opacity: 0.6;
        font-weight: 500;
    }

    .getting-started {
        font-size: 2rem;
    }

    .noteFA {
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-top: 50px;
        min-height: 40px;
        background-color: #e3e8ee;
        opacity: 0.8;
        border-left: 3px solid #a4a4a4;
        font-size: 14px;
    }
}

