body {
    font-family: "Poppins", sans-serif !important;
    margin: 0;
    padding: 0;
    cursor: url("../assets/images/cursor.png"), auto !important;
    overflow-x: hidden !important;
}

.components-holder {
    margin: 0 auto;
    max-width: 1100px;
    padding: 10px;
    overflow-x: hidden !important;
}

@media only screen and (max-width: 750px) { 
    .components-holder { 
        max-width: 100% !important;
        min-width: 100% !important;
    }
}