.containers {
    position: absolute;
    width: 100%;
    background-color:#ffffff;
    background-size: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 9999;
    background: repeating-linear-gradient(135deg, rgba(196, 39, 161, 0.05) 0, rgba(196, 39, 161, 0.05) 5%, #FFFFFF 0, #FFFFFF 50%) 0 / 20px 20px;
    background-color: #ffffff;
    box-shadow: 0 6px 10px -4px rgba(0,0,0, 0.07);
}

.header-holder {
    margin: 0 auto;
    max-width: 1110px;
    padding: 10px;
}

.header-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.logo-text-holder {
    display: flex;
    align-items: center;
}

.logo-text {
    font-size: 38px;
    font-family: 'Yesteryear', cursive;
    margin-left: 10px;
    font-weight: 600;
    opacity: 0.85;
}

.git {
    font-size: 28px !important;
    color: #ce20a5 !important;
    cursor: pointer;
}

.git:hover {
    font-size: 28px !important;
    color: #df21b2;
}

@media only screen and (max-width: 750px) { 
    .header-holder { 
        max-width: 100% !important;
    }
}