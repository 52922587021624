@keyframes heartbeat
{
    0% {
        -webkit-transform: scale(0.7);
      }
      
      100% {
        -webkit-transform: scale(1);
      }
}

.footerHolder {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 30px;
}

.heart-beat {
    color: red;
    animation: heartbeat infinite alternate 0.5s;
    margin-left: 5px;
    margin-right: 5px;
}